import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/enduserClosureResponse'

const enduserClosure = axios.create({
  baseURL
})

interceptorsSetup(enduserClosure)

export default class EnduserClosure {
  static async getEnduserInfo (endUser) {
    const result = await enduserClosure.get('/enduser/' + endUser)
    return result.data
  }

  static async postClosure (closure) {
    const result = await enduserClosure.post(`/`, { closure })
    return result.data
  }
}
