<template>
  <form @submit.prevent="submit">
    <div v-if="isLoading">
      <b-loading :active.sync="isLoading" is-full-page/>
    </div>
    <div v-else class="container">
      <div class="languageSwitcher">
        <language-switcher/>
      </div>
      <div v-if="!errorMessage" class="container is-fluid">
        <div class="columns hero is-primary is-centered">
          <div class="column section">
            <h1 class="title is-2 is-primary has-text-centered">
              {{ $t('consignee.consignee_declaration.welcome') }}
            </h1>
            <p class="has-text-centered">
              {{ enduser.enduserName }}
            </p>
            <p class="has-text-centered">
              {{ enduser.countryCode }}
            </p>
            <div class="card has-text-centered mt-3">
              <div class="card-content" style="white-space: pre-line;">
                {{ $t('closure_form.explainer_text') }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!submited">
          <div v-if="!closure.notClosing">
            <div class="column is-full">
              <div class="card has-text-centered">
                <div class="card-header">
                  <span class="card-header-title">
                    {{ $t('closure_form.start_date') }}
                  </span>
                </div>
                <div class="card-content">
                  <b-field>
                    <b-datepicker
                      v-model="closure.startDate"
                      name="startdate"
                      icon="calendar-alt"
                      placeholder="Start date"
                      :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
                    />
                  </b-field>
                </div>
              </div>
            </div>
            <div class="column is-full">
              <div class="card has-text-centered">
                <div class="card-header">
                  <span class="card-header-title">
                    {{ $t('closure_form.end_date') }}
                  </span>
                </div>
                <div class="card-content">
                  <b-field>
                    <b-datepicker
                      v-model="closure.endDate"
                      name="enddate"
                      icon="calendar-alt"
                      placeholder="End date"
                      :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
                    />
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-full">
            <div class="card has-text-centered">
              <div class="card-header">
                <span class="card-header-title">
                  {{ $t('closure_form.not_closing') }}
                </span>
              </div>
              <div class="card-content">
                <b-field>
                  <b-checkbox
                    v-model="closure.notClosing"
                    name="notClosed"
                  />
                </b-field>
              </div>
            </div>
          </div>
          <div v-if="!closure.notClosing" class="column is-full">
            <div class="card has-text-centered">
              <div class="card-header">
                <span class="card-header-title">
                  {{ $t('closure_form.comments') }}
                </span>
              </div>
              <div class="card-content">
                <b-field>
                  <b-input v-model="closure.comment" type="textarea"/>
                </b-field>
              </div>
            </div>
          </div>
          <div class="column has-text-centered is-full">
            <button class="button is-primary" @click="submit">
              {{ $t('consignee.consignee_declaration.submit') }}
            </button>
          </div>
        </div>
        <div v-if="submited">
          <div class="column is-full">
            <div class="card has-text-centered">
              <div class="card-header">
                <span class="card-header-title">
                  {{ $t('closure_form.new_closure') }}
                </span>
              </div>
              <div class="card-content">
                <b-field>
                  <button class="button is-primary" @click="newSubmission">
                    {{ $t('closure_form.new_closure_button') }}
                  </button>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import LanguageSwitcher from '../../components/common/languageSwitcher.vue'
import EnduserClosure from '../../services/v2/enduserClosure'

export default {
  components: {
    LanguageSwitcher
  },
  data () {
    return {
      submited: false,
      enduser: {
        enduserName: 'DefaultName'
      },
      token: this.$route.query.token,
      isLoading: true,
      errorMessage: '',
      invalidToken: false,
      closure: {
        enduserCode: null,
        endDate: null,
        startDate: null,
        comment: null,
        notClosing: false
      }
    }
  },
  computed: {
  },
  mounted: async function () {
    try {
      this.isLoading = true
      this.closure.enduserCode = this.$route.params.enduser_id
      EnduserClosure.getEnduserInfo(this.$route.params.enduser_id).then(response => {
        this.enduser = response[0]
      })
      this.isLoading = false
    } catch (error) {
      if (error.message === 'Invalid token') {
        this.invalidToken = true
        this.errorMessage = this.$t('consignee.consignee_declaration.invalid_token_message')
      } else {
        this.errorMessage = error.message
      }
      this.isLoading = false
    }
  },
  methods: {
    submit: async function () {
      if (!this.closure.endDate || !this.closure.startDate) {
        if (this.closure.notClosing === false) {
          this.$buefy.toast.open({ message: 'Please fill in a start and end date or choose not closing', type: 'is-danger' })
          return
        }
      }
      if (this.closure.endDate < this.closure.startDate) {
        this.$buefy.toast.open({ message: 'End date is before start date', type: 'is-danger' })
        return
      }
      if (!this.submited) {
        try {
          this.submited = true
          EnduserClosure.postClosure(this.closure).then(response => {
            this.closure = {
              enduserCode: this.$route.params.enduser_id,
              endDate: null,
              startDate: null,
              comment: null,
              notClosing: false
            }
          })
        } catch (error) {
          this.submited = false
          this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
        }
      }
    },
    newSubmission: async function () {
      this.submited = false
    }
  }
}
</script>

<style>
/* .body {
    max-width: 100%;
    overflow-x: hidden;
} */

.languageSwitcher {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}
</style>
